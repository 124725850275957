<template>
    <b-modal id="city-category-create-modal" title="City Category Create Modal" hide-footer scrollable size="lg"
      @hidden="resetModal" :no-close-on-esc="true" :no-close-on-backdrop="true" centered>
      <template #modal-title>
        <h2 class="m-0">Create City Category</h2>
      </template>
      <validation-observer ref="cityCategoryCreateFormValidation">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="12" sm="12" lg="12" xl="12">
                <validation-provider #default="{ errors }" name="category Id" rules="required">
                <b-form-group label-for="categoryId" :state="errors.length > 0 ? false : null">
                    <template #label>
                    Category Type <span class="text-danger">*</span>
                    </template>
                    <b-row>
                        <b-col>
                            <v-select id="categoryId" inputId="id" label="name" v-model="selectedCategoryId"
                            :options="categoryIds" placeholder="Category Id" class="v-style-chooser"/>
                        </b-col>
                    </b-row>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
                </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Name" label-for="name">
                <template #label>
                  Name <span class="text-danger">*</span>
                </template>
                  <validation-provider #default="{ errors }" name="Name"
                      :rules="{ required, regex: /^[0-9A-Z-()/#&''\s]*$/i }">
                      <b-form-input id="name" v-model="name" :state="errors.length > 0 ? false : null"
                      placeholder="Name" />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" sm="12" lg="12" xl="12">
                <validation-provider #default="{ errors }" name="RoomTypes" rules="required">
                <b-form-group label-for="roomTypes" :state="errors.length > 0 ? false : null">
                    <template #label>
                    Room Types <span class="text-danger">*</span>
                    </template>
                    <b-row>
                        <b-col>
                            <v-select id="roomTypes" inputId="id" label="name" v-model="selectedRoomTypes"
                            :options="roomTypes" placeholder="Room Types" class="v-style-chooser" multiple disabled/>
                        </b-col>
                    </b-row>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
                </validation-provider>
            </b-col>
          </b-row>
          <template>
          <b-row v-for="(value, key) in roomTypePrices" :key="`price_${key}`">
            <b-col md="12" sm="12" lg="12" xl="12">
              <b-row>
                <b-col v-for="(value_userType, key_userType) in userTypes" :key="`userType_${key_userType}`" md="4" sm="4" lg="4" xl="4">
                  <b-form-group :label-for="`${key}-${key_userType}-RoomTypePrice`">
                    <template #label> {{ key }} room price for {{ userTypeNames(value_userType) }}  </template>
                    <validation-provider #default="{ errors }" :name="`${key}-${key_userType}-RoomTypePrice`"
                        :rules="{ required, regex: /^[0-9]*$/i }">
                        <template v-if="key in roomTypePrices">
                        <b-form-input :id="`${key}-${key_userType}-RoomTypePrice`"  v-model="roomTypePrices[key][value_userType]" :state="errors.length > 0 ? false : null"
                        :placeholder="`${key} Room Type Price`" :ref="`${key}-${key_userType}-RoomTypePrice`"/>
                        </template>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
            <b-form-group class="text-right">
                <b-button type="submit" variant="primary" pill class="mr-1 mt-2" @click="validationForm">
                    Create
                </b-button>
            </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </template>
  
  <script>
  import Vue from "vue";
  import { mapActions, mapGetters } from "vuex";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "@validations";
  import VuePerfectScrollbar from "vue-perfect-scrollbar";
  import draggable from "vuedraggable";
  import util from "@/util.js";
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      VuePerfectScrollbar,
      draggable,
    },
    mixins: [util],
    props: ["showModal"],
    data() {
      return {
        perfectScrollbarSettings: {
          maxScrollbarLength: 150,
          wheelPropagation: false,
          useBothWheelAxes: false,
          suppressScrollX: true,
        },
        name: "",
        icon: null,
        iconURL: "",
        required,
        acceptedFiles: [".svg"],
        categoryIds: [
            {
                id: 1,
                name: "Cat 1"
            },
            {
                id: 2,
                name: "Cat 2"
            },
            {
                id: 3,
                name: "Cat 3"
            },
            {
                id: 4,
                name: "Cat 4"
            }
        ],
        selectedCategoryId: null,
        selectedRoomTypes: null,
        roomTypes: [],
        roomTypePrices: {},
      };
    },
    async mounted() {
      if (this.showModal) {
        try {
            const res = await this.getRoomTypesUnpaginated()
            this.roomTypes = res.data
            this.selectedRoomTypes = this.roomTypes
        } catch (error) {
          this.displayError(error);
        }
      }
    },
    methods: {
      ...mapActions({
        createCityCategory: "appData/createCityCategory",
        getRoomTypesUnpaginated: "appData/getRoomTypesUnpaginated",
      }),
      async validationForm() {
        const success = await this.$refs.cityCategoryCreateFormValidation.validate();
        if (success) {
          await this.submit();
        }
      },
      async submit() {
        try {
            let formData = new FormData();
            let selectedRoomTypesList = []
            let temp = {}
            for(let i = 0; i < this.selectedRoomTypes.length; i++) {
                temp = {}
                temp['id'] = this.selectedRoomTypes[i].id
                temp['name'] = this.selectedRoomTypes[i].name
                temp['price'] = this.roomTypePrices[this.selectedRoomTypes[i].name]
                selectedRoomTypesList.push(temp)
            }
            let dataToInsert = {
                name: this.name,
                category_id: this.selectedCategoryId.id,
                room_types: selectedRoomTypesList,
                created_by: this.getLoggedInUser.id,
                updated_by: this.getLoggedInUser.id,
            };
            formData.append("data", JSON.stringify(dataToInsert));
            console.log(dataToInsert);
            const res = await this.createCityCategory(formData);
            if (res.status === 201) {
                this.$swal({
                title: "City Category created successfully",
                icon: "success",
                });
                // await this.resetModal();
                this.$nextTick(() => {
                this.$bvModal.hide("city-category-create-modal");
                });
                this.$emit("modalClosed");
            }
        } catch (error) {
            this.displayError(error);
        }
      },
    
      reset() { },
      resetModal() {
      },
    },
    computed: {
      ...mapGetters({ getLoggedInUser: "appData/getUser" }),
    },
    watch: {
        selectedRoomTypes(newValue, oldValue) {
        if (newValue) {
            let temp = []
            for(let i=0; i<newValue.length; i++){
            temp.push(newValue[i].name)
            Vue.set(this.roomTypePrices, newValue[i].name, {});
            for(var key of Object.keys(this.userTypes)){
                Vue.set(this.roomTypePrices[newValue[i].name], this.userTypes[key], 0)
            }
            }
            for(let i=0; i<this.roomTypes.length; i++){
            if( !(temp.includes(this.roomTypes[i].name)) ){
                Vue.delete(this.roomTypePrices, this.roomTypes[i].name)
            }
            }
        }
        },
    },  
  };
  </script>
  
  <style lang="scss" scoped>
  .scroll-area-size {
    height: 45vh;
  }
  
  .scroll-area-size ul {
    height: 100%;
  }
  </style>
  
  