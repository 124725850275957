<template>
    <div>
      <div class="d-flex justify-content-center align-items-center flex-nowrap mb-1">
        <div class="
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-nowrap
                  mr-5
                ">
          <b-form-input id="nameFilter" v-model="nameFilter"
            placeholder="Search by name" />
        </div>
      </div>

      <div class="d-flex justify-content-center align-items-center flex-nowrap mb-1">
        <div class="
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-nowrap
                  mr-5
                ">
          <b-button variant="primary" pill @click="filter">
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span class="align-middle">Search</span>
          </b-button>
        </div>
  
        <div class="
                  d-flex
                  justify-content-center
                  align-items-center
                  flex-nowrap
                  mr-5
                ">
          <b-button variant="primary" pill @click="search">
            <feather-icon icon="RefreshCwIcon" class="mr-50" />
            <span class="align-middle">Refresh</span>
          </b-button>
        </div>
  
        <div class="text-right">
          <b-button variant="primary" pill @click="createCityCategory"
            v-if="hasPermission('city_category_create')">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Create</span>
          </b-button>
        </div>
      </div>

      <b-card>
        <b-table responsive :fields="fields" :items="cityCategories" details-td-class="m-0 p-0" small
          v-if="hasPermission('city_category_show')" :per-page="0" :busy="isBusy">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner :variant="'primary'" class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(created_by_data)="row">
            {{ row.item.created_by_data ? row.item.created_by_data.user : "" }}
          </template>
          <template #cell(updated_by_data)="row">
            {{ row.item.updated_by_data ? row.item.updated_by_data.user : "" }}
          </template>
          <template #cell(manage)="row">
          <div class="d-flex align-items-center">
            <b-button variant="info" pill size="sm" class="mr-1" @click="viewCityCategory(row.item)"
              v-if="hasPermission('city_category_read')">
              View
            </b-button>
            <template>
              <b-button variant="info" pill size="sm" class="mr-1" @click="editCityCategory(row.item)"
                v-if="hasPermission('city_category_update')">
                Edit
              </b-button>
            </template>
            <template v-if="!row.item.assigned">
              <b-button variant="danger" pill size="sm" class="mr-1" @click="deleteCityCategory(row.item)"
                v-if="hasPermission('city_category_delete')">
                Delete
              </b-button>
            </template>
            </div>
          </template>
        </b-table>
        <b-row>
                <!-- <b-col md="10">
                    <b-pagination :total-rows="totalDataLength"
                                  :per-page="per_page"
                                  align="center"
                                  v-model="currentPage" />
                </b-col> -->
                <b-col md="2">
                    <div style="float:left">
                        <h5 style="margin: 0; display: inline;" class="text-primary">Count: </h5>
                        <h5 style="margin: 0; display: inline;" align="right">
                            <strong>
                                {{totalDataLength}}
                            </strong>
                        </h5>
                    </div>
                </b-col>
            </b-row>
  
        <div class="d-flex justify-content-center align-items-center flex-nowrap m-2">
          <b-pagination size="md" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"></b-pagination>
        </div>
      </b-card>
      <city-category-create-modal @modalClosed="onModalClosed"
        :showModal="visible" :key="`create-${cityCategoryCreateModalCount}`" />
      <city-category-edit-modal :cityCategory="cityCategory" :allowEdit="allowEdit" @modalClosed="onModalClosed"
        :showModal="visible" :key="`edit-${cityCategoryEditModalCount}`" />
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import util from "@/util.js";
  import CityCategoryCreateModal from "@/components/booking/admin/CityCategoryCreateModal.vue";
  import CityCategoryEditModal from "@/components/booking/admin/CityCategoryEditModal.vue";
  
  export default {
    components: {
      CityCategoryCreateModal,
      CityCategoryEditModal,
    },
    data() {
      return {
        fields: [
          { key: "manage", label: "Manage" },
          { key: "category_id_name", label: "Category Type" },
          { key: "name", label: "Category Name" },
          { key: "created_at", label: "Created At" },
          { key: "created_by_data", label: "Created By" },
          { key: "updated_at", label: "Last updated" },
          { key: "updated_by_data", label: "Updated By" },
        ],
        currentPage: 1,
        perPage: 0,
        totalItems: 0,
        cityCategory: null,
        cityCategories: [],
        cityCategoryCreateModalCount: 0,
        cityCategoryEditModalCount: 0,
        visible: false,
        nameFilter: "",
        isBusy: false,
        allowEdit: true,
        totalDataLength: 0,
      };
    },
    mixins: [util],
    async mounted() {
      // await this.search();
    },
    methods: {
      ...mapActions({
        getCityCategories: "appData/getCityCategories",
        delete: "appData/deleteCityCategory",
      }),
      async filter() {
        this.currentPage = 1;
        await this.search();
      },
      async search() {
        this.isBusy = true;
        await this.fetchPaginatedData();
        this.isBusy = false;
      },
      createCityCategory() {
          this.cityCategoryCreateModalCount += 1;
          this.visible = true;
          this.$nextTick(() => {
            this.$bvModal.show("city-category-create-modal");
          });
      },
      editCityCategory(cityCategory) {
        this.cityCategory = cityCategory;
        this.cityCategoryEditModalCount += 1;
        this.visible = true;
        this.allowEdit = true;
        this.$nextTick(() => {
          this.$bvModal.show("city-category-edit-modal");
        });
      },
      viewCityCategory(cityCategory) {
        this.cityCategory = cityCategory;
        this.cityCategoryEditModalCount += 1;
        this.visible = true;
        this.allowEdit = false;
        this.$nextTick(() => {
            this.$bvModal.show("city-category-edit-modal");
        });
      },
      //HARD DELETE
      async deleteCityCategory(cityCategory) {
        try {
          this.$swal({
            title:
              "Are you sure?",
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: "Confirm",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-danger ml-1",
            },
            buttonsStyling: false,
          }).then(async (result) => {
            if (result.value) {
              try {
                const res = await this.delete({
                  pk: cityCategory.id,
                });
                if (res.status === 204) {
                  this.$swal({
                    title: "City Category deleted successfully",
                    icon: "success",
                  });
                  this.$nextTick(() => {
                    this.search();
                  });
                  this.$emit("modalClosed");
                }
              } catch (error) {
                this.show = false;
                if (error.response.status == 500) {
                  // this.displayError("Kindly make sure this mess does not have any dependencies");
                  this.$swal({
                    title: "Kindly make sure this city category does not have any dependencies",
                    icon: "error",
                  });
                }
                else {
                  this.displayError(error);
                }
              }
            } else {
              this.show = false;
            }
          });
        } catch (error) {
          this.displayError(error);
        }
      },

      //SOFT DELETE
      // deleteCityCategory(cityCategory) {
      //   try {
      //     this.$swal({
      //       title:
      //         "Are you sure? This cityCategory will be deleted.",
      //       icon: "warning",
      //       showCloseButton: true,
      //       showCancelButton: true,
      //       confirmButtonText: "Confirm",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //         cancelButton: "btn btn-danger ml-1",
      //       },
      //       buttonsStyling: false,
      //     }).then(async (result) => {
      //       if (result.value) {
      //         try {
      //           const res = await this.updateBookingCityCategory({
      //             payload: {
      //               is_delete: true,
      //               updated_by: this.getLoggedInUser.id,
      //             },
      //             pk: cityCategory.id,
      //           });
      //           let response_string = "City Category deleted successfully";
      //           let response_msg = response_string;
      //           if (res.data.response_msg) {
      //             response_msg = response_msg.concat(res.data.response_msg);
      //           }
      //           if (res.status === 200) {
      //             this.$swal({
      //               title: response_msg,
      //               icon: "success",
      //             });
      //             this.$nextTick(() => {
      //               this.fetchPaginatedData();
      //             });
      //             this.$emit("modalClosed");
      //           }
      //         } catch (error) {
      //           this.show = false;
      //           this.displayError(error);
      //         }
      //       } else {
      //         this.show = false;
      //       }
      //     });
      //   } catch (error) {
      //     this.displayError(error);
      //   }
      // },
      async onModalClosed() {
        await this.fetchPaginatedData();
        this.visible = false;
      },
      async fetchPaginatedData() {
        try {
          let tempParams = {
            pageNumber: this.currentPage,
          };
          if (this.nameFilter) {
            tempParams["name"] = this.nameFilter;
          }
          const res = await this.getCityCategories(tempParams);
          this.cityCategories = res.data.results;
          
          this.totalItems = res.data.count;
          this.perPage = res.data.per_page;
          this.totalDataLength = res.data.count;
        } catch (error) {
          this.displayError(error);
        }
      },
    },
    computed: {
      ...mapGetters({
        hasPermission: "appData/hasPermission",
        getLoggedInUser: "appData/getUser",
      }),
    },
    watch: {
      currentPage: async function (val) {
        await this.fetchPaginatedData();
      },
    },
  };
  </script>
  
  <style></style>
  